
	frappe.templates['performance_feedback_summary'] = `<div class="feedback-summary-section my-4 d-flex">
	<div class="rating-summary-numbers col-3">
		<h2 class="average-rating">{{ average_rating }}</h2>
		<div class="feedback-count mb-2">
			{{ cstr(feedback_count)}} {{ feedback_count > 1 ? __("reviews") : __("review") }}
		</div>

		<!-- Ratings Summary -->
		{%= frappe.render_template("rating",
				{number_of_stars: number_of_stars, average_rating: average_rating, for_summary: true}
			)
		%}

		<div class="mt-2">{{ cstr(average_rating) + " " + __("out of") + " " }} {{number_of_stars}}</div>
	</div>

	<!-- Rating Progress Bars -->
	<div class="rating-progress-bar-section col-4 ml-4">
		{% for(let i=0, l=reviews_per_rating.length; i<l; i++) { %}
			<div class="col-sm-4 small rating-bar-title">
				{{ i+1 }} star
			</div>
			<div class="row">
				<div class="col-md-7">
					<div class="progress rating-progress-bar" title="{{ reviews_per_rating[i] }} % of reviews are {{ i+1 }} star">
						<div class="progress-bar progress-bar-cosmetic" role="progressbar"
							aria-valuenow="{{ reviews_per_rating[i] }}"
							aria-valuemin="0" aria-valuemax="100"
							style="width: {{ reviews_per_rating[i] }}%;">
						</div>
					</div>
				</div>
				<div class="col-sm-1 small">
					{{ reviews_per_rating[i] }}%
				</div>
			</div>
		{% } %}
	</div>
</div>
`;
